import React, { Component } from "react";
import { Button, Checkbox, Form, Label, Modal, Transition } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import CustomerCreationFormFields from "../../../assets/resources/customer_creation";
import IconButton from "../../shared/buttons/IconButton";

class CustomerCreationFormModal extends Component {

  state = {
    required: [],
    showDeleteButtons: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentDidMount() {
    const formFields = CustomerCreationFormFields;
    const required = [];

    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  render() {
    const { values, loadings, options, editing, approving } = this.props;
    const formFields = CustomerCreationFormFields;

    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon>
        <Modal.Header>
          {approving ? "Approving " + values.name : values.name}
          {values.status == false && values.id &&
            <Label color="red" size="small" style={{ marginLeft: '10px' }}>Inactive</Label>
          }
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            {editing ?
              <div>
                {formFields.map((field, i) => {
                  if (Array.isArray(field)) {
                    return (
                      <Form.Group widths='equal' key={i}>
                        {
                          field.map((groupField, ii) => {
                            if (groupField.control === Checkbox) {
                              groupField.checked = values[groupField.name];
                              groupField.readOnly = false;
                            } else {
                              groupField.value = values[groupField.name];
                            }

                            return (
                              <Form.Field
                                key={ii}
                                {...groupField}
                                options={options[groupField.name]}
                                loading={loadings[groupField.name]}
                                onChange={this.props.handleChange}
                              />
                            );
                          })
                        }
                      </Form.Group>
                    );
                  } else {
                    if (field.control === Checkbox) {
                      field.checked = values[field.name];
                    } else {
                      field.value = values[field.name];
                    }
                    return (
                      <Form.Field
                        key={i}
                        {...field}
                        options={options[field.name]}
                        loading={loadings[field.name]}
                        onChange={this.props.handleChange}
                      />
                    );
                  }
                })}

                {/* Add status field for editing mode */}
                <Form.Field>
                  <label>Status</label>
                  <Form.Checkbox
                    toggle
                    label={values.status != false ? "Active" : "Inactive"}
                    checked={values.status != false}
                    name="status"
                    onChange={(e, data) => this.props.handleChange(e, { name: "status", value: data.checked })}
                  />
                </Form.Field>
                {console.log('STATUS', values.status)}
              </div>
              :
              <div>
                {approving &&
                  <Form.Field
                    control="input"
                    width={8}
                    value={this.props.customerCode}
                    name="code"
                    label="Customer SAP code"
                    required={true}
                    maxLength="13"
                    onChange={(e) => this.props.handleChangeCode(e)}
                  />
                }
                {values.active ?
                  <div className='custom-form-field'>
                    <label>Customer SAP code</label>
                    <div>{values.code}</div>
                  </div> : ""}

                {/* Show status field in view mode */}
                <div className='custom-form-field'>
                  <label>Status</label>
                  <div>
                    {values.status != false ? (
                      <Label color="green" style={{ margin: 0 }}>Active</Label>
                    ) : (
                      <Label color="red" style={{ margin: 0 }}>Inactive</Label>
                    )}
                  </div>
                </div>

                {formFields.map((field, i) => {
                  if (Array.isArray(field)) {
                    return (
                      <Form.Group widths='equal' key={i}>
                        {field.map((groupField, ii) => {
                          if (typeof values[groupField.name] !== 'undefined') {
                            return (
                              <div className='custom-form-field' key={ii}>
                                <label>{groupField.label}</label>
                                <div>{values[groupField.name]}</div>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Form.Group>
                    )
                  } else if (values[field.name] !== "") {
                    return (
                      <div className='custom-form-field' key={field.name}>
                        <label>{field.label}</label>
                        {field.control !== Checkbox ?
                          <div>{values[field.name]}</div>
                          : values[field.name] === 0 ?
                            <div>No</div>
                            : <div>Yes</div>}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {editing ?
            <div>
              <Button
                floated='left'
                className='cancel'
                onClick={this.props.onClose}>Cancel</Button>
              <Can I="customer_creation_request:Update" a="customers">
                <IconButton
                  floated='right'
                  icon='plus'
                  label="Submit"
                  disabled={this.checkFormInvalidity()}
                  onClick={this.props.onSubmit}
                />
              </Can>
            </div>
            : approving ?
              <div>
                <Button
                  floated='left'
                  className='cancel'
                  onClick={this.props.onCancelApproving}>Cancel</Button>
                <Can I="customer_creation_request:Update" a="pending_customers">
                  <IconButton
                    floated='right'
                    icon='plus'
                    label="Submit"
                    disabled={this.props.customerCode === ""}
                    onClick={this.props.approveSubmit}
                  />
                </Can>
              </div>
              :
              <div>
                <Can I="customer_creation_request:Update" a="pending_customers">
                  {!this.props.values.active ? <Button floated='left' negative onClick={this.showDeleteButtons}>Discard</Button> : ""}
                </Can>
                <Transition visible={this.state.showDeleteButtons} animation="fade right" duration={500}>
                  <span style={{ float: "left", padding: "0 10px" }}>
                    <span style={{ margin: "10px" }}>Are you sure?</span>
                    <Button className='grey'
                      negative
                      onClick={() => this.setState({ showDeleteButtons: false })}>No</Button>
                    <Button onClick={this.props.onDelete}>Yes</Button>
                  </span>
                </Transition>

                {this.props.values.active && this.props.values.id ? (
                  <Can I="customer_creation_request:Update" a="customers">
                    <Button
                      floated='left'
                      color={this.props.values.status != false ? "red" : "green"}
                      onClick={this.props.onToggleStatus}
                    >
                      {this.props.values.status != false ? "Disable" : "Enable"}
                    </Button>
                  </Can>
                ) : <></>}

                <Can I='customer_creation_request:Update' a="customers">
                  <Button floated='right' onClick={this.props.onEdit}>Edit</Button>
                </Can>
                {!this.props.values.active ?
                  <Can I="customer_creation_request:Update" a="pending_customers">
                    <Button floated='right' onClick={this.props.onApprove}>Approve</Button>
                  </Can>
                  : ""}
              </div>
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CustomerCreationFormModal;