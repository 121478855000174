import { API } from 'aws-amplify';
import { GET_INTERNAL_LEVELS } from '../administration/internal_level';

export const GET_PFES = 'GET_PFES';
export const GET_PFE = 'GET_PFE';
export const LOADING_PFES = 'LOADING_PFES';
export const LOADING_PFE = 'LOADING_PFE';
export const LOADING_PFE_CREATION = 'LOADING_PFE_CREATION';
export const STOP_LOADING_PFES = 'STOP_LOADING_PFES';
export const GET_COMPANIES = 'GET_COMPANIES';
export const LOADING_COMPANIES = 'LOADING_COMPANIES';


export function getPfes(pfes = {}) {
  return { type: GET_PFES, pfes };
}

export function loadingPfes() {
  return { type: LOADING_PFES };
}

export function stopLoadingPfes() {
  return { type: STOP_LOADING_PFES };
}

export function loadingPfeCreation() {
  return { type: LOADING_PFE_CREATION };
}

export function getPfe(pfe = {}) {
  return { type: GET_PFE, pfe };
}

export function loadingPfe() {
  return { type: LOADING_PFE };
}

export function getCompanies(companies = []) {
  return { type: GET_COMPANIES, companies };
}

export function startGetPfes(searchOption, searchValue, params) {
  return dispatch => {
    dispatch(loadingPfes());

    const init = {
      queryStringParameters: {
        order_direction: params.order_direction,
        order: params.order,
        size: params.size,
        start: params.start,
        projectStatus: params.projectStatus,

        type: searchOption,
        query: searchValue
      }
    };
    //console.log(init);
    API.get('pfe', '/', init)
      .then(pfes => {
        //console.log('response Pfes', pfes);
        dispatch(getPfes(pfes));
      })
      .catch(error => {
        console.error('ERROR fetching Pfes', error);
        dispatch(getPfes({}));
      });
  };
}

export function resetSearch() {
  return getPfes([]);
}

/*
export function startGetPfesSearch(searchOption, searchValue, params) {
  //console.log("[startGetPfesSearch]: ", searchOption, searchValue, params);
  return dispatch => {
    dispatch(loadingPfes());

    const init = {
      queryStringParameters: {
        order_direction: 'desc',
        order: 'creation_date',
        size: params.size,
        start: params.start,
        type: searchOption,
        query: searchValue
      },
    };
    //console.log(init);
    API.get('pfes', '/', init)
      .then(pfes => {
        //console.log('response Pfes', pfes);
        dispatch(getPfes(pfes));
      })
      .catch(error => {
        console.error('ERROR fetching Pfes', error);
        dispatch(getPfes({}));
      });
  };
}
*/
export function startGetPfesById(pfeId) {
  return dispatch => {
    dispatch(loadingPfe());
    API.get('pfes', '/' + pfeId + "/", {})
      .then(pfe => {
        //console.log('response Pfe', pfe);
        dispatch(getPfe(pfe));
      })
      .catch(error => {
        console.error('ERROR fetching Pfe', error);
        dispatch(getPfe({}));
      });
  };
}

export function startCreatePfes(params, onSuccess, onFailure) {
  console.log('ACTION Pfes - startCreatePfes ', params);
  return dispatch => {
    dispatch(loadingPfeCreation());

    API.post('pfeNext', '/', { body: params, })
      .then(response => {
        console.log('response pfe_creation', response);
        dispatch(startGetPfesById(response.pfe_id));
        onSuccess(response);
      })
      .catch(error => {
        console.log('error', error);
        dispatch(stopLoadingPfes());
        onFailure(error);
      });
  };
}

export function startConfirmPFE(pfeId, params, onSuccess, onFailure) {
  //console.log('ACTION pfe - startConfirmPFE ', pfeId);
  return dispatch => {
    dispatch(loadingPfes());
    API.put('pfes', '/' + pfeId + '/confirm/', {})
      .then(response => {
        dispatch(stopLoadingPfes());
        //console.log('response startConfirmPFE', response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log(error.response);
        if (error.response.result === 406) {
          dispatch(stopLoadingPfes());
          onFailure(error.response.data.error);
        } else {
          //console.log('error', error);
          dispatch(stopLoadingPfes());
          onFailure(error);
        }
      });
  };
}

export function startApprovePFE(pfeId, params, onSuccess, onFailure) {
  //console.log('ACTION pfe - startApprovePFE ', pfeId);
  return dispatch => {
    dispatch(loadingPfes());
    API.put('pfes', '/' + pfeId + '/approve/', {})
      .then(response => {
        dispatch(stopLoadingPfes());
        //console.log('response startApprovePFE', response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(stopLoadingPfes());
        onFailure(error);
      });
  };
}

export function startRejectPFE(pfeId, onSuccess, onFailure) {
  //console.log('ACTION pfe - startRejectPFE ', pfeId);
  return dispatch => {
    dispatch(loadingPfes());
    API.put('pfes', '/' + pfeId + '/reject/', {
      // body: {
      //   note: note
      // }
    })
      .then(response => {
        dispatch(stopLoadingPfes());
        //console.log('response startRejectPFE', response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(stopLoadingPfes());
        onFailure(error);
      });
  };
}

export function startUpdatePFESheet(pfeId, onSuccess, onFailure) {
  //console.log('ACTION pfe - ststartUpdatePFESheetart ', pfeId);
  return dispatch => {
    dispatch(loadingPfes());
    API.put('pfes', '/' + pfeId + '/update/', {})
      .then(response => {
        //console.log('response startRejectPFE', response);
        dispatch(stopLoadingPfes());

        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(stopLoadingPfes());
        onFailure(error);
      });
  };
}

export function startCrPFESheet(pfeId, params, onSuccess, onFailure) {
  //console.log('ACTION pfe - startCrPFESheet ', pfeId, params);
  return dispatch => {
    dispatch(loadingPfeCreation());
    API.post('pfes', '/' + pfeId + '/cr/', {
      body: params
    })
      .then(response => {
        //console.log('response startCrPFESheet', response);
        dispatch(stopLoadingPfes());
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(stopLoadingPfes());
        onFailure(error);
      });
  };
}

export function startUpdateOfferCode(pfeId, offer_request_id, onSuccess, onFailure) {
  //console.log('ACTION pfe - ststartUpdatePFESheetart ', pfeId);
  return dispatch => {
    dispatch(loadingPfes());
    API.put('pfes', '/' + pfeId + '/offerCode/', { body: { offer_code_id: offer_request_id } })
      .then(response => {
        //console.log('response startRejectPFE', response);
        dispatch(stopLoadingPfes());

        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(stopLoadingPfes());
        onFailure(error);
      });
  };
}

export function notifyAdmin(pfeId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingPfes());
    API.post('pfes', '/' + pfeId + '/email/notifyAdministration/', { body: { pfe_id: pfeId } })
      .then(response => {
        dispatch(stopLoadingPfes());
        onSuccess(response)
      })
      .catch(error => {
        dispatch(stopLoadingPfes());
        onFailure(error);
      })
  }
}

export function startGetCompanies() {
  return dispatch => {
    dispatch(loadingPfe());

    API.get('company', '/')
      .then(res => {
        dispatch(getCompanies(res));
      })
      .catch(error => {
        console.error('ERROR fetching Companies', error);
        dispatch(getCompanies([]));
      })
  };
}
