import { API } from "aws-amplify";
export const CREATE_OFFER_REQUEST_SUCCESS = "CREATE_OFFER_REQUEST_SUCCESS";
export const CREATE_OFFER_REQUEST_ERROR = "CREATE_OFFER_REQUEST_ERROR";
export const LOADING_OFFER_REQUEST = "LOADING_OFFER_REQUEST";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";

export function createOfferRequestSuccess() {
  return { type: CREATE_OFFER_REQUEST_SUCCESS  };
}
export function createOfferRequestError() {
  return { type: CREATE_OFFER_REQUEST_ERROR  };
}

export function loadingOfferRequest() {
  return { type: LOADING_OFFER_REQUEST };
}

export function getCustomers(customers = {}) {
  return { type: GET_CUSTOMERS, customers };
}

export function loadingCustomers() {
  return { type: LOADING_CUSTOMERS };
}

export function startCreateOfferRequest(params, onSuccess, onFailure) {
  return (dispatch) => {
    let data = {
      body: {
        ...params
      }
    };
    dispatch(loadingOfferRequest());
    API.post("projects", "/", data)
      .then(response => {
        dispatch(createOfferRequestSuccess());
        //console.log("[OFFER REQUEST] create response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(createOfferRequestError());
        //console.log("[OFFER REQUEST] create error", error);
        onFailure(error);
      });
  };
}

export function startGetCustomers(permission_code = "") {
  return (dispatch) => {
    dispatch(loadingCustomers());
    API.get("customersActive", "/", {headers: {'permission-code': permission_code}})
      .then(customers => {
        //console.log("customers", customers);
        dispatch(getCustomers(customers));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getCustomers([]));
      });
  };
}
