import * as React from "react";
import { Button, Container, Form, Header, Icon, Segment, Step, Table } from "semantic-ui-react";
import {
  startCreateCompany,
  startDeleteCompany, startEditCompany,
  startGetCompanies,
  startGetCompanyFromId
} from "../../../../store/actions/shared/company";
import { startGetCurrencies } from "../../../../store/actions/shared/currency";
import { startGetCountries } from "../../../../store/actions/shared/country";
import { connect } from "react-redux";
import _ from "lodash";
import { startGetExpenseTypes, startGetHourTypes } from "../../../../store/actions/core/administration/manageTypes";
import TypesTable from "../../../../components/core/administration/TypesTable";
import ManageCompaniesForm from "../../../../components/core/administration/manage-companies/ManageCompaniesForm";
import CompanyDetails from "../../../../components/core/administration/manage-companies/CompanyDetails";
import Can from "../../../../abilities/Can";

const companyCols = [
  { id: "name", name: "Company Name" },
  { id: "currency_code", name: "Currency" },
  { id: "country_code", name: "Country" },
  { id: "active", name: "Active", type: "boolean" }
];

class ManageCompaniesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modify: false,
      details: false,
      action: "",
      values: {
        double_receipt: 0
      },
      hour_types: [],
      expense_types: [],
      step: 1,
      error_code: "",
      editCode: true
    };
  }

  componentDidMount() {
    this.props.getCurrencies();
    this.props.getCountries();
    this.props.getCompanies();
    this.props.getHourTypes();
    this.props.getExpenseTypes();
  }

  handleRowClick = rowInfo => {
    this.props.getCompany(rowInfo.id);
    this.setState({
      values: rowInfo,
      details: true,
      editCode: false
    });
    //console.log(rowInfo, this.state.values, this.state.details);
  };

  handleSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res);
    }

    const onSuccess = res => {
      //console.log("onSuccess", res);
      this.setState({
        values: {},
        hour_types: [],
        expense_types: [],
        modify: false,
        details: false,
        step: 1
      });
    };

    if (this.state.details) {
      let currency = this.props.options.currency_code.find(o => o.value === this.state.values.currency_code).key;
      let values = {
        ...this.state.values,
        currency: currency
      };
      //console.log("EDITING COMPANY", values);
      this.props.editCompany(values.id, values, onSuccess, onFailure);
    } else {
      this.props.createCompany(this.state.values, onSuccess, onFailure);
    }
  };


  handleDelete = () => {
    //console.log("DELETING ", this.state.values);
    const onSuccess = res => {
      //console.log("onSuccess", res);
      this.setState({
        values: {},
        details: false,
        modify: false,
        editCode: true
      });
    };

    const onFailure = res => {
      //console.log("onError", res);
    };

    this.props.deleteCompany(this.state.values.id, onSuccess, onFailure);

  };

  handleCheckboxChange = (e, data) => {
    let types = [];
    switch (this.state.step) {
      case 2:
        types = this.state.hour_types;
        if (!data.checked) {
          let index = types.indexOf(data.id);
          if (index > -1) {
            types.splice(index, 1);
          }
        } else {
          types.push(data.id);
        }
        //console.log("TYPES ", types);
        this.setState({
          hour_types: types
        });
        break;
      case 3:
        types = this.state.expense_types;
        if (!data.checked) {
          let index = types.indexOf(data.id);
          if (index > -1) {
            types.splice(index, 1);
          }
        } else {
          types.push(data.id);
        }
        //console.log("TYPES ", types);
        this.setState({
          expense_types: types
        });
        break;
      default:
        break;
    }
  }

  handleChange = (e, data) => {
    //console.log(data);
    if (data.value === "" && data.checked === undefined) {
      data.value = undefined;
    } else if (data.checked !== undefined) data.value = data.checked;

    if (data.value < 0) data.value = 0;

    let values = {};
    if (data.name === "currency_code") {
      let currency = data.options.find(o => o.value === data.value).key;
      values = {
        ...this.state.values,
        currency: currency,
        currency_code: data.value
      };
    } else if (data.name === "code") {
      //console.log("dentro");
      const re = /^[a-zA-Z]{5}$/;
      if (!re.test(String(data.value))) {
        this.setState({
          error_code: "Invalid code! Code must have five letter."
        });
      } else {
        this.setState({
          error_code: ""
        });
      }
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    } else {
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    }

    this.setState({
      values: values
    });

    //console.log(this.state.values);
  };

  onCancel = () => {
    this.setState({
      values: {},
      modify: false,
      details: false,
      editCode: false,
      selectedRow: null
    });
  };

  generateExtraSummary = () => {

    let componentHourType = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Hour Types</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.hour_types.map((h) => (

            <Table.Row>
              <Table.Cell>{this.props.hourTypes.find(o => o.id === h).name}</Table.Cell>
              <Table.Cell>{this.props.hourTypes.find(o => o.id === h).code}</Table.Cell>
            </Table.Row>
          )
          )}
        </Table.Body>
      </Table>
    );

    let componentExpenseType = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Expense Types</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.expense_types.map((h) => (

            <Table.Row>
              <Table.Cell>{this.props.expenseTypes.find(o => o.id === h).name}</Table.Cell>
              <Table.Cell>{this.props.expenseTypes.find(o => o.id === h).code}</Table.Cell>
            </Table.Row>
          )
          )}
        </Table.Body>
      </Table>
    );

    return (
      <div>
        {componentHourType}
        {componentExpenseType}
      </div>
    );
  };

  handleBack = () => {
    switch (this.state.step) {
      case 2:
        this.setState({
          step: 1
        });
        break;
      case 3:
        this.setState({
          step: 2
        });
        break;
      case 4:
        this.setState({
          step: 3
        });
        break;
      default:
        break;
    }
    ;
  }

  handleNext = () => {
    let payload = this.state.values;
    let types = [];
    let types_id = [];
    switch (this.state.step) {
      case 1:
        types = this.props.hourTypes.filter(h => h.status);
        types_id = types.map(h => h.id);
        if (this.state.details) {
          //console.log("HEREEEEEEE ID ", this.props.company.company_hours.map(h => h.id), types_id);
          //console.log("UNIONE ", _.union(types_id, edit_types));
          let edit_types = this.props.company.company_hours.map(h => h.id);
          payload.hour_types = _.union(types_id, edit_types);
        } else {
          payload.hour_types = types_id;
        }
        this.setState({
          step: 2,
          hour_types: payload.hour_types,
          values: payload
        });
        break;
      case 2:
        types = this.props.expenseTypes.filter(h => h.status);
        types_id = types.map(h => h.id);
        if (this.state.details) {
          //console.log("HEREEEEEEE ID ", this.props.company.company_expenses.map(h => h.id), types_id);
          //console.log("UNIONE ", _.union(types_id, edit_types));
          let edit_types = this.props.company.company_expenses.map(h => h.id);
          payload.expense_types = _.union(types_id, edit_types);
        } else {
          payload.expense_types = types_id;
        }
        payload.hour_types = this.state.hour_types;
        this.setState({
          step: 3,
          expense_types: payload.expense_types,
          values: payload
        });
        break;
      case 3:
        payload.expense_types = this.state.expense_types;
        this.setState({
          step: 4,
          values: payload
        });
        break;
      default:
        break;
    }
    //console.log("YEEEEE VALUES", this.state.values);
  };

  render() {
    const { modify, details } = this.state;
    let stepComponent = null;

    this.state.step === 1
      ? (stepComponent = (
        <ManageCompaniesForm
          values={this.state.values}
          handleChange={this.handleChange}
          onSubmit={this.handleSubmit}
          loadings={this.props.loadings}
          options={this.props.options}
          summary={false}
          onCancel={this.onCancel}
          onNext={this.handleNext}
          onBack={this.handleBack}
          modify={modify && details}
          extra={this.generateExtraSummary()}
          editCode={this.state.editCode}
          active={this.state.values.hasOwnProperty("active") ? this.state.values.active : true}
          error={this.state.error_code}
        />
      ))
      : this.state.step === 2
        ? (stepComponent = (
          <Segment clearing>
            <Form>
              {this.props.hourTypes.map((h, i) => (
                <Form.Group key={i}>
                  <Form.Checkbox
                    id={h.id}
                    required={h.status}
                    label={h.text}
                    readOnly={false}
                    checked={this.state.hour_types.indexOf(h.id) > -1}
                    onChange={this.handleCheckboxChange}
                    className="hour_type"
                    toggle
                  />
                </Form.Group>
              )
              )
              }
              <div>
                <Button className="grey"
                  floated='left'
                  onClick={this.handleBack}>
                  Back
              </Button>
                <Button floated='right'
                  onClick={this.handleNext}>
                  Next
              </Button>
              </div>
            </Form>
          </Segment>

        ))
        : this.state.step === 3
          ? (stepComponent = (<Segment clearing>
            <Form>
              {this.props.expenseTypes.map((h, i) => (
                <Form.Group key={i}>
                  <Form.Checkbox
                    id={h.id}
                    label={h.text}
                    required={h.status}
                    readOnly={false}
                    checked={this.state.expense_types.indexOf(h.id) > -1}
                    onChange={this.handleCheckboxChange}
                    toggle
                  />
                </Form.Group>
              )
              )
              }
              <div>
                <Button className="grey"
                  floated='left'
                  onClick={this.handleBack}>
                  Back
                </Button>
                <Button floated='right'
                  onClick={this.handleNext}>
                  Next
                </Button>
              </div>
            </Form>
          </Segment>

          ))
          : (stepComponent = (
            <ManageCompaniesForm
              values={this.state.values}
              handleChange={this.handleChange}
              onSubmit={this.handleSubmit}
              loadings={this.props.loadings}
              options={this.props.options}
              summary={true}
              onCancel={this.onCancel}
              onNext={this.handleNext}
              onBack={this.handleBack}
              extra={this.generateExtraSummary()}
              editCode={this.state.editCode}
              active={this.state.values.hasOwnProperty("active") ? this.state.values.active : true}
            />
          ));


    return (
      <Container>
        <Header>Manage Companies {modify && !details ? " > New" : modify && details ? " > Edit" : null}</Header>

        <Can I='company:Read' a='all'>
          {this.state.modify ? (
            <div>
              <Step.Group fluid>
                <Step active={this.state.step === 1}>
                  <Icon name="clipboard" />
                  <Step.Content>
                    <Step.Title>Company Details</Step.Title>
                    <Step.Description>
                      Insert company name and currency
                  </Step.Description>
                  </Step.Content>
                </Step>

                <Step active={this.state.step === 2}>
                  <Icon name="clock" />
                  <Step.Content>
                    <Step.Title>Hour Types</Step.Title>
                    <Step.Description>Assign hour types</Step.Description>
                  </Step.Content>
                </Step>

                <Step active={this.state.step === 3}>
                  <Icon name="dollar sign" />
                  <Step.Content>
                    <Step.Title>Expense Types</Step.Title>
                    <Step.Description>Assign expense types</Step.Description>
                  </Step.Content>
                </Step>
                <Step active={this.state.step === 4}>
                  <Icon name="check circle" />
                  <Step.Content>
                    <Step.Title>Confirm</Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
              <Segment clearing>{stepComponent}</Segment>
            </div>
          ) : this.state.details && this.props.company && this.props.hourTypes && this.props.expenseTypes ?
              (
                <CompanyDetails
                  data={this.props.company}
                  onEdit={() => this.setState({ modify: true, step: 1, editCode: false })}
                  handleBack={() => this.setState({ modify: false, values: {}, details: false, editCode: false })}
                  handleDelete={this.handleDelete}
                />
              ) : (
                <Segment>
                  <Button
                    floated="right"
                    onClick={() => this.setState({ modify: true, editCode: true })}
                  >
                    New Company
            </Button>
                  <TypesTable
                    typeName="company"
                    loading={this.props.loading}
                    types={this.props.companies}
                    columns={companyCols}
                    onSelectRow={this.handleRowClick}
                  />
                </Segment>
              )}
        </Can>
      </Container>

    );
  }
}

const mapStateToProps = state => ({
  options: {
    currency_code: _.toArray(
      _.mapValues(state.currencyReducer.currencies, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.code
        };
      })
    ),
    country_code: _.toArray(
      _.mapValues(state.countryReducer.countries, o => {
        return {
          key: o.code,
          value: o.code,
          text: o.code
        };
      })
    )
  },
  loadings: {
    currency_code: state.currencyReducer.loading,
    country_code: state.countryReducer.loading
  },
  hourTypes: _.filter(_.toArray(
    _.mapValues(state.manageTypesReducer.hours, o => {
      return {
        id: o.id,
        name: o.name,
        code: o.code,
        status: o.default_type === 1,
        active: o.active,
        text: o.name + " - " + o.code
      };
    })
  ), item => item.active === 1),
  expenseTypes: _.filter(_.toArray(
    _.mapValues(state.manageTypesReducer.expenses, o => {
      return {
        id: o.id,
        name: o.name,
        code: o.code,
        status: o.default_type === 1,
        active: o.active,
        text: o.name + " - " + o.code
      };
    })
  ), item => item.active === 1),
  companies: state.companyReducer.companies,
  company: state.companyReducer.company,
  loading: state.companyReducer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    getCurrencies: () => dispatch(startGetCurrencies()),
    getCountries: () => dispatch(startGetCountries()),
    getCompanies: () => dispatch(startGetCompanies()),
    getHourTypes: () => dispatch(startGetHourTypes()),
    getExpenseTypes: () => dispatch(startGetExpenseTypes()),
    createCompany: (params, onSuccess, onFailure) => dispatch(startCreateCompany(params, onSuccess, onFailure)),
    getCompany: companyId => dispatch(startGetCompanyFromId(companyId)),
    deleteCompany: (companyId, onSuccess, onFailure) => dispatch(startDeleteCompany(companyId, onSuccess, onFailure)),
    editCompany: (companyId, params, onSuccess, onFailure) => dispatch(startEditCompany(companyId, params, onSuccess, onFailure))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCompaniesContainer);
