import React, { Component } from "react";
import DatePicker from 'react-datepicker';
import { Button, Form } from "semantic-ui-react";
import IconButton from "../../shared/buttons/IconButton";

const FIELDS = {
    "description": "Description",
    "prj_suffix": "Project Code Suffix",
    "billing": {
      "currency_type": "Project Billing Currency",
      "company_type" : "Billing Entity",
    },
    "project_manager": "Project/Bid Manager",
    "hours_expenses_approver": "Hours / Expenses Approver",
    "clients": {
      "final_client_type": "Final Client",
      "billing_client_type": "Billing Client"
    },
    "types": {
      "activity_type_text": "Activity type",
      "project_type_text": "Project Billing Type",
      "invoice_type_text" : "Invoice type",
      "offering_type_text" : "Offering type",
      "product_type_text" : "Product type"
    }
  };


class OfferRequestForm extends Component {

  state = {
    required: [
      "description",
      "project_type",
      "currency_type",
      "company_type",
      "project_manager",
      "final_client_type",
      "billing_client_type",
      "activity_type",
      "offering_type",
      "invoice_type",
      ...(this.props.showUseExpensesApprover ? ["hours_expenses_approver"] : [])
    ],
      focused: false,
  };


  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
      let vals = this.props.values;
      let disabled = false;
      for (let i = 0; i < this.state.required.length; i++) {
          if (!vals[this.state.required[i]] || this.props.error !== "") disabled = true;
      }
      if(vals.hasOwnProperty("activity_type") && vals.activity_type === "PD" && !vals.hasOwnProperty("product_type")){
          disabled = true;
      }
      if(vals.hasOwnProperty("product_type") && this.props.has_decommission_date===1 && (!this.props.decommission_date && !this.props.is_decommission_date_disabled)){
        disabled = true;
      }
    return disabled;
  };

  /**
   * Toggle the is_decommission_date_disabled state for product projects
   */
  toggleTerminationDate = () =>{
    this.props.toggleDecommissionDateDisabled();
    this.props.onDecommissionDateChange(null);
  }


  render() {


    const { values, loadings, options, summary, extra, product_options, error, project_options, offering_options, invoice_options, showUseExpensesApprover} = this.props;
    const summaryFields = FIELDS;

    return (
      <React.Fragment>
          <Form style={{ clear: "both" }}>
            {summary ?
              <div>
                {Object.keys(summaryFields).map((key) => {
                  if (typeof summaryFields[key] === "object") {
                    return (
                      <Form.Group widths='equal' key={key}>
                        {Object.keys(summaryFields[key]).map(kk => {
                          if (typeof values[kk] !== 'undefined') {
                            return (
                              <div className='custom-form-field' key={kk}>
                                <label>{summaryFields[key][kk]}</label>
                                <div>{values[kk]}</div>
                                </div>
                              );
                          }else{
                            return "";
                          }
                        })}
                      </Form.Group>
                    );
                  } else if (typeof values[key] !== 'undefined') {
                    return (
                      <div className='custom-form-field' key={key}>
                        <label>{summaryFields[key]}</label>
                            <div>{values[key]}</div>
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
                {extra &&
                  extra}
              </div>

              : (
                <div>
                    <Form.Input
                        name={"description"}
                        label={"Description"}
                        onChange={this.props.handleChange}
                        required={true}
                        width={8}
                        value={values.hasOwnProperty("description") ? values.description : ""}
                    />
                    <Form.Input
                        name={"prj_suffix"}
                        label={"Project Code Suffix"}
                        required={false}
                        maxLength={5}
                        onChange={this.props.handleChange}
                        error={error !== ""}
                        width={8}
                        value={values.hasOwnProperty("prj_suffix") ? values.prj_suffix : ""}
                    />
                    <Form.Select
                        name={"currency_type"}
                        label={"Project Billing Currency"}
                        loading={loadings.currency_type}
                        options={options.currency_type}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("currency_type") ? values.currency_type : ""}
                    />
                    <Form.Select
                        name={"company_type"}
                        label={"Billing Entity"}
                        loading={loadings.company_type}
                        options={options.company_type}
                        onChange={this.props.handleChangeCompany}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("company_type") ? values.company_type : ""}
                    />
                    <Form.Select
                        name={"project_manager"}
                        label={"Project/Bid Manager"}
                        loading={loadings.project_manager}
                        options={options.project_manager}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("project_manager") ? values.project_manager : ""}
                        onSearchChange={(e, data) => {
                          const query = data.searchQuery.toLowerCase();
                          const filtered = options.project_manager
                            .filter(pm => pm.searchText.includes(query))
                            .sort((a, b) => a.text.localeCompare(b.text));
                          return filtered;
                        }}
                    />
                    {showUseExpensesApprover && <Form.Select
                        name={"hours_expenses_approver"}
                        label={"Hours / Expenses Approver"}
                        loading={loadings.expenses_approver}
                        options={options.expenses_approver}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("hours_expenses_approver") ? values.hours_expenses_approver : ""}
                    /> }
                    <Form.Select
                        name={"final_client_type"}
                        label={"Final Client"}
                        loading={loadings.final_client_type}
                        options={options.final_client_type}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("final_client_type") ? values.final_client_type : ""}
                    />
                    <Form.Select
                        name={"billing_client_type"}
                        label={"Billing Client"}
                        loading={loadings.billing_client_type}
                        options={options.billing_client_type}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("billing_client_type") ? values.billing_client_type : ""}
                    />
                    {values.hasOwnProperty("activity_type") && values.hasOwnProperty("project_type") ?
                        <Form.Input
                            name={"activity_type"}
                            label={"Activity type"}
                            required={true}
                            readOnly
                            value = {values.activity_type_text}
                            width={8}
                        /> :
                    <Form.Select
                        name={"activity_type"}
                        label={"Activity type"}
                        loading={loadings.activity_type}
                        options={options.activity_type}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("activity_type") ? values.activity_type : ""}
                    />}
                    {values.hasOwnProperty("project_type") && values.hasOwnProperty("invoice_type") ?
                            <Form.Input
                                name={"project_type"}
                                label={"Project type"}
                                required={true}
                                readOnly
                                value = {values.project_type_text}
                                width={8}
                            />
                        : values.hasOwnProperty("activity_type") ?
                        <Form.Select
                        name={"project_type"}
                        label={"Project type"}
                        loading={loadings.project_type}
                        options={project_options}
                        onChange={this.props.handleChange}
                        required={true}
                        search={true}
                        width={8}
                        value={values.hasOwnProperty("project_type") ? values.project_type : ""}
                    /> : ""}
                    {values.hasOwnProperty("invoice_type") && values.hasOwnProperty("offering_type") ?
                        <Form.Input
                            name={"invoice_type"}
                            label={"Invoice type"}
                            required={true}
                            readOnly
                            value = {values.invoice_type_text}
                            width={8}
                        />
                        : values.hasOwnProperty("project_type") ?
                        <Form.Select
                            name={"invoice_type"}
                            label={"Invoice type"}
                            loading={loadings.invoice_type}
                            options={invoice_options}
                            onChange={this.props.handleChange}
                            required={true}
                            search={true}
                            width={8}
                            value={values.hasOwnProperty("invoice_type") ? values.invoice_type : ""}
                        /> : ""}
                    {values.hasOwnProperty("offering_type") && values.hasOwnProperty("product_type") ?
                        <Form.Input
                            name={"offering_type"}
                            label={"Offering type"}
                            required={true}
                            readOnly
                            value = {values.offering_type_text}
                            width={8}
                        />
                        : values.hasOwnProperty("invoice_type") ?
                        <Form.Select
                            name={"offering_type"}
                            label={"Offering type"}
                            loading={loadings.offering_type}
                            options={offering_options}
                            onChange={this.props.handleChange}
                            required={true}
                            search={true}
                            width={8}
                            value={values.hasOwnProperty("offering_type") ? values.offering_type : ""}
                        /> : ""}
                  {values.hasOwnProperty("offering_type") && values.activity_type === "PD" ?
                    <Form.Select
                      options={product_options}
                      name="product_type"
                      label="Product Type"
                      search={true}
                      required={true}
                      onChange={this.props.handleChange}
                      width={8}
                      noResultsMessage={'No products found.'}
                      value={values.hasOwnProperty("product_type") ? values.product_type : ""}
                    /> : ""}
                  {values.hasOwnProperty("product_type") && this.props.has_decommission_date===1 ?
                    <Form.Field>
                    <label>Expiration date</label>
                    <DatePicker
                    id="decommission_date"
                    value={this.props.decommission_date}
                    disabled={this.props.is_decommission_date_disabled}
                    onChange={this.props.onDecommissionDateChange}
                    required={true}
                    showYearDropdown={true}
                    scrollableYearDropdown={true}
                    showMonthDropdown={true}
                    minDate={new Date()}
                    />
                  <Form.Checkbox
                  label={"Never Expires"}
                  value={this.props.is_decommission_date_disabled}
                  checked={this.props.is_decommission_date_disabled}
                  onChange={this.toggleTerminationDate}
                  />
                  </Form.Field>
                     : ""}
                </div>
              )}

          </Form>

          {!summary ?
            <div>
            <br/>
              <Button floated='left' className='cancel' onClick={this.props.onCancel}>Cancel</Button>
              <p style={{"textAlign":"right","color":"red"}}>{error}</p>
              <Button floated='right' onClick={this.props.onNext} disabled={this.checkFormInvalidity()}>Next</Button>
            </div>
            :
            <div>
              <br/>
              <Button className="grey"
                      floated='left'
                      onClick={this.props.onBack}>
                Edit
              </Button>
              <IconButton
                floated='right'
                icon='plus'
                label="Submit"
                onClick={this.props.onSubmit}
              />
            </div>
          }
      </React.Fragment>
    );
  }
}

export default OfferRequestForm;
