import _, { debounce } from "lodash";
import matchSorter from 'match-sorter';
import moment from "moment";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Grid,
  Label,
  Loader,
  Modal,
  Popup,
  Radio,
  Search,
  Segment,
} from "semantic-ui-react";
import PaginatedReusableTable from '../../../../components/shared/PaginatedReusableTable';
import ReusableHeaderWithHelp from '../../../../components/shared/ReusableHeaderWithHelp';
import { history } from "../../../../routers/AppRouters";
import { startFilterProjects, startOfferCodesExport } from "../../../../store/actions/core/administration/order_value";
import { formatNumber } from "../../../../utils/functionUtils";

const searchOptions = [
  { value: "project", label: "Project code" },
  { value: "project_name", label: "Project description" },
  { value: "manager", label: "Project manager name" },
  { value: "company", label: "Company" }
]

const ProjectStatuses = [
  { value: "all", text: "All" },
  { value: "active", text: "Active" },
  { value: "inactive", text: "Inactive" },
  { value: "pending", text: "Pending" },
  { value: "rejected", text: "Rejected" },
];

const OrderValueStatuses = [
  { value: "all", text: "All" },
  { value: "ok", text: "OK" },
  { value: "ko", text: "KO" },
  { value: "na", text: "NA" },
];

const OrderValueCols = [
  {
    Header: 'Order Status',
    accessor: 'order_status',
    id: 'order_status',
    Cell: row => {
      switch (row.value) {
        case "OK":
          return "OK";
        case "KO":
          return "KO";
        case "NA":
          return "NA";
        default:
          return "";
      }
    },
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true;
      } else {
        //console.log("FILTER:", row.order_status, filter.value);
        return row.order_status === filter.value;
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="OK">Ok</option>
        <option value="KO">Ko</option>
        <option value="NA">Na</option>
      </select>
    )
  },
  {
    Header: 'Project Code',
    accessor: 'project_code',
    id: 'project_code',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Manager',
    accessor: 'project_manager',
    id: 'project_manager',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'AEP Ord Val PFE',
    accessor: 'tep_order_value_pfe',
    id: 'tep_order_value_pfe',
    filterAll: true,
    Cell: row => {
      return formatNumber(row.value);
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Delta Rev. Closure',
    accessor: 'delta_revenues_closure',
    id: 'delta_revenues_closure',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },


  {
    Header: 'Offer Value',
    accessor: 'offer_value',
    id: 'offer_value',
    filterAll: true,
    Cell: row => {
      return formatNumber(row.value);
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'DOCS Order Val.',
    accessor: 'docs_order_value',
    id: 'docs_order_value',
    filterAll: true,
    Cell: row => {
      return formatNumber(row.value);
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    id: 'notes',
    filterAll: true,
    Cell: row => {
      return unescape(row.value);
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Activity Type',
    accessor: 'activity_type',
    id: 'activity_type',
    filterAll: true,
    Cell: row => {
      return row.value == null ? "" : row.value;
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Status',
    accessor: 'project_status',
    id: 'project_status',
    filterAll: true,
    Cell: row => {
      return row.value == null ? "" : row.value;
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
];

export class AdminOrderValueContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openSearchOption: false,
      searchOption: "project",
      searchValue: "",
      page: 0,
      pageSize: 20,
      selectedRow: {},
      filters: {
        projectStatus: "active",
        orderValueStatus: "all",
        startDate: moment().startOf("year"),
        endDate: moment(),

        useDate: false,
        start: 0,
        size: 20,
      },
      offerCodes: {
        openModal: false,
        startDate: moment().startOf("year"),
        endDate: moment(),
        projectStatus: "active",
      }
    };

    this.debouncedSearch = debounce((option, value, filters) => {
      if (value.length >= 3) {
        this.props.getFilterProjects(option, value, filters);
      }
    }, 1500);
  }

  componentWillMount() {
    if (history.action === 'PUSH') {
      this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, this.state.filters);
    } else {
      if (this.props.filterParam !== undefined) {
        this.setState(prevState => ({
          ...prevState,
          searchValue: this.props.filterParam.searchValue,
          searchOption: this.props.filterParam.searchOption,
          filters: this.props.filterParam.filters
        }), () => {
          this.props.getFilterProjects(
            this.state.searchOption,
            this.state.searchValue,
            this.state.filters
          );
        });
      } else {
        this.props.getFilterProjects(
          this.state.searchOption,
          this.state.searchValue,
          this.state.filters
        );
      }
    }
  }

  componentDidMount() { }

  onPageChange = pageIndex => {
    //console.log("PAGE INDEX", pageIndex);
    this.setState({ page: pageIndex });
    // TODO fetch new data if necessary
    let filters = this.state.filters;
    filters['start'] = pageIndex * this.state.pageSize;
    filters['size'] = this.state.pageSize;

    //console.log(filters);
    // ask new order values
    this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, filters);
  };

  onSelectRow = selectedRow => {
    //console.log(selectedRow);

    this.setState(prevState => ({
      ...prevState,
      selectedRow: selectedRow
    }));
    if ("project_id" in selectedRow) {
      history.push({
        pathname: "/admin/order-value/" + selectedRow.project_id, state: selectedRow
      })
    }
  }

  openOfferCodesExport = () => {
    this.setState((prevState) => ({
      ...prevState,
      offerCodes: {
        ...prevState.offerCodes,
        openModal: true
      }
    }));
  }

  closeOfferCodesExport = () => {
    this.setState((prevState) => ({
      ...prevState,
      offerCodes: {
        ...prevState.offerCodes,
        openModal: false,
        startDate: moment().startOf("year"),
        endDate: moment(),
      }
    }));
  }

  openSearchOption = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSearchOption: true,
    }));
  };

  closeSearchOption = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSearchOption: false,
    }));

  };

  handleOnSearchOption = (e, { value }) => {
    // default setting is by project name
    this.setState((prevState) => ({
      ...prevState,
      searchOption: value,
      openSearchOption: false,
      searchValue: "",
    }));
  };

  onOfferCodesModalSelectChange = (e, data) => {
    this.setState(prevState => ({
      ...prevState,
      offerCodes: {
        ...prevState.offerCodes,
        [data.name]: data.value,
      }
    }));
  }

  onSelectChange = (e, data) => {
    //console.log(data.name, data.value);

    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [data.name]: data.value
      }
    }));

    let filters = this.state.filters;
    filters[data.name] = data.value;

    this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, filters);
  };

  onOfferCodesModalDatesChange = ({ startDate, endDate }) => {
    this.setState(prevState => ({
      ...prevState,
      offerCodes: {
        ...prevState.offerCodes,
        startDate: moment(startDate),
        endDate: moment(endDate),
      }
    }));

  }

  onOfferCodesModalExport = () => {

    this.setState(prevState => ({
      ...prevState,
      offerCodes: {
        ...prevState.offerCodes,
        openModal: false,
      }
    }));

    let params = {
      startDate: this.state.offerCodes.startDate,
      endDate: this.state.offerCodes.endDate,
      projectStatus: this.state.offerCodes.projectStatus
    }
    console.log("[Offer Codes]", this.state.offerCodes);
    console.log("[Params]", params);
    this.props.getOfferCodesExport(params);
  }


  onDatesChange = ({ startDate, endDate }) => {
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        startDate: moment(startDate),
        endDate: moment(endDate)
      }
    }));

    let filters = this.state.filters;
    filters.startDate = moment(startDate);
    filters.endDate = moment(endDate);
    this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, filters);
  };

  handleChange = (e, { value }) => {
    this.setState((prevState) => ({
      ...prevState,
      searchValue: value
    }));

    if (value.length >= 3) {
      this.debouncedSearch(this.state.searchOption, value, this.state.filters);
    } else {
      //DO NOTHING
      console.log("LENGTH: ", this.state.searchValue.length);
    }
  };

  handleCheckboxChange = (e, { checked }) => {
    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        useDate: checked,
      }
    }))
  };

  manualSearch = () => {
    //console.log(this.state);
    this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, this.state.filters);
  };

  render() {

    let data = this.props.filterProjects && this.props.filterProjects.data ? this.props.filterProjects.data : [{}];
    const meta = this.props.filterProjects && this.props.filterProjects.meta ? this.props.filterProjects.meta : undefined;

    let pages = -1;
    if (meta) {
      pages = Math.ceil(meta.total_elements / meta.size);
    }

    //console.log(data);
    //console.log(meta);
    //console.log(pages);

    return (
      <Container>
        <ReusableHeaderWithHelp title="Admin Order Value" />
        <Dimmer active={this.props.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Segment clearing>
          {/* TODO: 21-010 DE-COMMENT THIS
          <IconButton
              loading={this.props.loading || this.props.loading_offerCodes_export}
              icon="download"
              label="Export Offer Codes"
              onClick={this.openOfferCodesExport}
              floated='right'
          />
          */}
          <Modal
            dimmer="blurring"
            open={this.state.offerCodes.openModal}
            onClose={this.closeOfferCodesExport}
            size="mini"
            closeIcon
          >
            <Modal.Header>Offer Code Export</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>Pick a date range</label>
                  <DateRangePicker
                    startDateId="startDate"
                    endDateId="endDate"
                    startDatePlaceholderText="Start Date"
                    endDatePlaceholderText="End Date"
                    startDate={this.state.offerCodes.startDate}
                    endDate={this.state.offerCodes.endDate}
                    onDatesChange={this.onOfferCodesModalDatesChange}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                      this.setState({ focusedInput });
                    }}
                    isOutsideRange={() => false}
                    //isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, "days"))}
                    showDefaultInputIcon={true}
                    small={true}
                    keepOpenOnDateSelect={true}
                    reopenPickerOnClearDates={true}
                    hideKeyboardShortcutsPanel={true}
                    initialVisibleMonth={() => moment().subtract(1, "month")}
                    minimumNights={0}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Select
                    placeholder="Filter by..."
                    name="projectStatus"
                    options={this.props.options.projectStatuses}
                    onChange={this.onOfferCodesModalSelectChange}
                    value={this.state.offerCodes.projectStatus}
                    label="Project Status"
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.onOfferCodesModalExport}> Export Offer Codes</Button>
            </Modal.Actions>
          </Modal>

          <Modal
            dimmer="blurring"
            open={this.state.openSearchOption}
            onClose={this.closeSearchOption}
            size="mini"
            closeIcon
          >
            <Modal.Header>Select search option</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Form size="massive">
                  {searchOptions.map((field, i) => {

                    return (
                      <Form.Field key={i}>
                        <Radio
                          label={field.label}
                          value={field.value}
                          checked={this.state.searchOption === field.value}
                          onChange={this.handleOnSearchOption}
                        />
                      </Form.Field>
                    );


                  })}

                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.closeSearchOption}> Select</Button>
            </Modal.Actions>
          </Modal>
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search {(searchOptions.filter(x => x.value == this.state.searchOption))[0].label}</Label>
                  <Popup
                    content={"Type " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + " digits to start searching"}
                    trigger={(
                      <Label color="teal" circular>
                        ?
                      </Label>
                    )}
                  />
                  <Search
                    name="search"
                    input={{ fluid: true }}
                    onSearchChange={this.handleChange}
                    placeholder={"Search all " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + "..."}
                    showNoResults={false}
                    minCharacters={3}
                    size="large"
                    loading={this.props.loading}
                    value={this.state.searchValue}
                  />
                  <Button
                    basic
                    style={{ 'margin': '0px 10px 0px 5px' }}
                    content={"Search Option: " + ((searchOptions.filter(x => x.value == this.state.searchOption))[0].label).toUpperCase()}
                    onClick={this.openSearchOption}
                    color='teal'
                    floated='right'
                    icon='filter'
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Form.Input type="hidden" />
                  <label>Project Status</label>
                  <Form.Select
                    placeholder="Filter by..."
                    name="projectStatus"
                    options={this.props.options.projectStatuses}
                    onChange={this.onSelectChange}
                    value={this.state.filters.projectStatus}
                  />
                  <label>Order Value Status</label>
                  <Form.Select
                    placeholder="Filter by..."
                    name="orderValueStatus"
                    options={this.props.options.orderValueStatuses}
                    onChange={this.onSelectChange}
                    value={this.state.filters.orderValueStatus}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Input type="hidden" />
                  <Form.Checkbox
                    label="Filter by date"
                    name="useDate"
                    checked={this.state.filters.useDate}
                    onChange={this.handleCheckboxChange}
                  />
                  <Form.Field>

                    <label>Pick a date range</label>
                    <DateRangePicker
                      required
                      startDateId="startDate"
                      endDateId="endDate"
                      startDatePlaceholderText="Start Date"
                      endDatePlaceholderText="End Date"
                      startDate={this.state.filters.startDate}
                      endDate={this.state.filters.endDate}
                      onDatesChange={this.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                      }}
                      isOutsideRange={() => false}
                      showDefaultInputIcon={true}
                      small={true}
                      keepOpenOnDateSelect={true}
                      reopenPickerOnClearDates={true}
                      hideKeyboardShortcutsPanel={true}
                      initialVisibleMonth={() => moment().subtract(1, "month")}
                      minimumNights={0}
                      disabled={!this.state.filters.useDate}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>
        {this.props.filterProjects && !_.isEmpty(this.props.filterProjects.data) ? (
          <Segment>
            <div style={{ marginTop: '10px' }}>
              <PaginatedReusableTable
                manual={pages > 1 ? true : false}
                filterable={pages > 1 ? false : true}
                sortable={pages > 1 ? false : true}
                loading={this.props.loading}
                columns={OrderValueCols}
                data={data}
                onClick={this.onSelectRow}
                onPageChange={this.onPageChange}
                page={this.state.page}
                pages={pages}
                pageSize={this.state.pageSize}
              />
            </div>
          </Segment>
        )
          : (null)
        }

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  /*
  _.toArray(
    _.mapValues(state.orderValueReducer.filterProjects, o => {
      return {
        key: o.code,
        value: o.code,
        text: o.code
      };
    })
  */
  return ({
    loading: state.orderValueReducer.loading,
    loading_offerCodes_export: state.orderValueReducer.loading_offerCodes_export,
    filterProjects: state.orderValueReducer.filterProjects,
    filterParam: state.orderValueReducer.filterParam,
    options: {
      projectStatuses: ProjectStatuses,
      orderValueStatuses: OrderValueStatuses
    }
  })
};

function mapDispatchToProps(dispatch) {
  return {
    getFilterProjects: (searchOption, searchValue, params) => dispatch(startFilterProjects(searchOption, searchValue, params)),
    getOfferCodesExport: (params) => dispatch(startOfferCodesExport(params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminOrderValueContainer);
