import { API, Storage } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import uuidv4 from "uuid/v4";
import * as moment from "moment";
import constants from "../../../../utils/constants";

export const GET_TR_EXPENSES = "GET_TR_EXPENSES";
export const LOADING_TR_EXPENSES = "LOADING_TR_EXPENSES";
export const TR_EXPENSES_ERROR = "TR_EXPENSES_ERROR";

export const DELETE_TR_EXPENSE = "DELETE_TR_EXPENSE";
export const DELETE_TR_EXPENSES = "DELETE_TR_EXPENSES";

export const deleteTrExpense = (expenseId) => {
  return { type: DELETE_TR_EXPENSE, expenseId };
};

export const deleteTrExpenses = (expenses) => {
  return { type: DELETE_TR_EXPENSES, expenses };
};


export const trExpensesError = () => {
  return { type: TR_EXPENSES_ERROR };
};

export const loadingTrExpenses = () => {
  return { type: LOADING_TR_EXPENSES };
};

export const getTrExpenses = (expenses) => {
  return { type: GET_TR_EXPENSES, expenses };
};


export const startGetTrExpenses = (cn_code, params, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrExpenses());
    const init = {
      queryStringParameters: {
        ...params
      },
      headers: { "permission-code": permission_code }
    };
    API.get("tr_expenses", "/" + cn_code + "/", init)
      .then(expenses => {
        dispatch(getTrExpenses(expenses));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(trExpensesError());
      });
  };
};

const createExpenseSuccessToast = () => toast({
  title: "Timereport",
  description: "New Expenses inserted successfully",
  type: "success",
  icon: "dollar",
  time: constants.TOAST_SUCCESS_TIMEOUT
});

const editExpenseSuccessToast = () => toast({
  title: "Timereport",
  description: "Expense edited successfully",
  type: "success",
  icon: "dollar",
  time: constants.TOAST_SUCCESS_TIMEOUT
});

const createExpenseErrorToast = () => toast({
  title: "Timereport",
  description: "The expense hasn't been created. Try Again",
  type: "error",
  time: constants.TOAST_ERROR_TIMEOUT
});

const editExpenseErrorToast = () => toast({
  title: "Timereport",
  description: "The expense hasn't been edited. Try Again",
  type: "error",
  time: constants.TOAST_ERROR_TIMEOUT
});


export const startCreateTrExpense = (cn_code, params, refetchParams, file, secondReceipt, permission_code = "", read_permission_code = "") => {
  //console.log("startCreateTrExpense with ", params, refetchParams);
  return async (dispatch) => {
    dispatch(loadingTrExpenses());

    if (file) {
      let fileS3Key = undefined;
      let secondReceiptS3Key = undefined;

      const extension = file.name.toLowerCase().split(".").pop();
      const filename = moment().format("x") + "-" + uuidv4() + "." + extension;

      const result = await Storage.put(filename, file, {
        contentType: file.type
      })
      fileS3Key = result.key

      if (secondReceipt) {
        const extension = secondReceipt.name.toLowerCase().split(".").pop();
        const filename = moment().format("x") + "-" + uuidv4() + "." + extension;

        const result = await Storage.put(filename, secondReceipt, {
          contentType: secondReceipt.type
        })
        secondReceiptS3Key = result.key
      }

      const init = {
        body: {
          ...params,
          receipt_key: fileS3Key,
          second_receipt_key: secondReceipt ? secondReceiptS3Key : undefined,
        },
        headers: { "permission-code": permission_code }
      };
      API.post("tr_expenses", "/", init)
        .then(() => {
          createExpenseSuccessToast();
          dispatch(startGetTrExpenses(cn_code, refetchParams, read_permission_code));
        })
        .catch(error => {
          //console.log("error", error);
          dispatch(trExpensesError());
          Storage.remove(fileS3Key).then((res) => console.log("Storage remove", res))
            .catch((err) => console.log("Storage remove error", err));
          Storage.remove(secondReceiptS3Key).then((res) => console.log("Storage remove", res))
            .catch((err) => console.log("Storage remove error", err));
          createExpenseErrorToast();
        });
    } else {
      const init = {
        body: {
          ...params
        },
        headers: { "permission-code": permission_code }
      };
      API.post("tr_expenses", "/", init)
        .then(() => {
          createExpenseSuccessToast();
          dispatch(startGetTrExpenses(cn_code, refetchParams, read_permission_code));
        })
        .catch(error => {
          //console.log("error", error);
          dispatch(trExpensesError());
          createExpenseErrorToast();
        });

    }
  };
};

export const startEditTrExpense = (userCN, expenseId, params, refetchParams, file, secondReceipt, permission_code = "", read_permission_code = "") => {
  //console.log("[startEditTrExpense] with params", params, "and refetchParams", refetchParams);
  return async (dispatch) => {
    dispatch(loadingTrExpenses());

    if (file) {
      let fileS3Key = undefined;
      let secondReceiptS3Key = undefined;

      const extension = file.name.toLowerCase().split(".").pop();
      const filename = moment().format("x") + "-" + uuidv4() + "." + extension;

      const result = await Storage.put(filename, file, {
        contentType: file.type
      })
      fileS3Key = result.key

      if (secondReceipt) {
        const extension = secondReceipt.name.toLowerCase().split(".").pop();
        const filename = moment().format("x") + "-" + uuidv4() + "." + extension;

        const result = await Storage.put(filename, secondReceipt, {
          contentType: secondReceipt.type
        })
        secondReceiptS3Key = result.key
      }

      const init = {
        body: {
          ...params,
          receipt_key: fileS3Key,
          second_receipt_key: secondReceipt ? secondReceiptS3Key : undefined,
        },
        headers: { "permission-code": permission_code }
      };
      API.put("tr_expenses", "/expense/" + expenseId + "/", init)
        .then(() => {
          editExpenseSuccessToast();
          dispatch(startGetTrExpenses(userCN, refetchParams, read_permission_code));
        })
        .catch(error => {
          //console.log("error", error);
          dispatch(trExpensesError());
          Storage.remove(fileS3Key).then((res) => console.log("Storage remove", res))
            .catch((err) => console.log("Storage remove error", err));
          Storage.remove(secondReceiptS3Key).then((res) => console.log("Storage remove", res))
            .catch((err) => console.log("Storage remove error", err));
            editExpenseErrorToast();
        });
    } else {
      const init = {
        body: {
          ...params
        },
        headers: { "permission-code": permission_code }
      };
      API.put("tr_expenses", "/expense/" + expenseId + "/", init)
        .then(() => {
          editExpenseSuccessToast();
          dispatch(startGetTrExpenses(userCN, refetchParams, read_permission_code));
        })
        .catch(error => {
          //console.log("error", error);
          dispatch(trExpensesError());
          editExpenseErrorToast();
        });

    }
  };
};

export const startDeleteTrExpense = (userID, isDummy, expenseId, S3Key, secondReceiptS3Key, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrExpenses());
    const init = {
      queryStringParameters: {
        user_id: userID,
        is_dummy: isDummy
      },
      headers: { "permission-code": permission_code }
    };
    API.del("tr_expenses", "/expense/" + expenseId + "/", init)
      .then(() => {
        toast(
          {
            title: "Timereport",
            description: "Expense deleted successfully",
            type: "success",
            icon: "dollar",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        if (S3Key) {
          Storage.remove(S3Key).then((res) => console.log("Storage remove", res))
            .catch((err) => console.log("Storage remove error", err));
        }
        if (secondReceiptS3Key) {
          Storage.remove(secondReceiptS3Key).then((res) => console.log("Storage remove", res))
            .catch((err) => console.log("Storage remove error", err));
        }
        dispatch(deleteTrExpense(expenseId));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(trExpensesError());
        toast(
          {
            title: "Timereport",
            description: "Couldn't delete expense.",
            type: "error",
            icon: "exclamation",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};


export const GET_TR_EXPENSE_TYPES = "GET_TR_EXPENSE_TYPES";
export const LOADING_TR_EXPENSE_TYPES = "LOADING_TR_EXPENSE_TYPES";

export const getTrExpenseTypes = (expenseTypes) => {
  return { type: GET_TR_EXPENSE_TYPES, expenseTypes };
};


export const loadingTrExpenseTypes = () => {
  return { type: LOADING_TR_EXPENSE_TYPES };
};

export const startGetTrExpenseTypes = (userID, is_dummy, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrExpenseTypes());
    const init = {
      queryStringParameters: { user: userID, src: "time-report", is_dummy: is_dummy },
      headers: { "permission-code": permission_code }
    };
    API.get("expenseTypes", "/", init)
      .then(expenseTypes => {
        dispatch(getTrExpenseTypes(expenseTypes));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(getTrExpenseTypes([]));
        toast(
          {
            title: "Error",
            description: "Couldn't retrieve expense types.",
            type: "error",
            icon: "exclamation",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};
