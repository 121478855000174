import { API } from "aws-amplify";

export const GET_BUDGETS = "GET_BUDGETS";
export const LOADING_BUDGETS = "LOADING_BUDGETS";
export const GET_BUDGET = "GET_BUDGET";
export const LOADING_BUDGET = "LOADING_BUDGET";
export const LOADING_CUTOFF_BUDGET = "LOADING_CUTOFF_BUDGET";

export function getBudgets(budgets = {}, filterParam) {
  return { type: GET_BUDGETS, budgets, filterParam };
}

export function loadingBudgets() {
  return { type: LOADING_BUDGETS };
}

export function getBudget(budget = {}) {
  return { type: GET_BUDGET, budget };
}

export function loadingBudget() {
  return { type: LOADING_BUDGET };
}

export function loadingBudgetCutoff() {
  return { type: LOADING_CUTOFF_BUDGET };
}

export function resetSearchBudget(){
  return getBudgets([], {});
}


export function startGetBudgets(params) {
  const init = {
    queryStringParameters: {
      size: params.size,
      start: params.start,
      projectStatus: params.projectStatus,

      order_direction: params.order_direction,
      order: params.order,
      type: params.type ? params.type : "project",
      query: params.query
    },
  };

  let filterParam = {
    searchValue: params.query,
    searchOption: params.type,
    projectStatus: params.projectStatus,
  }

  return (dispatch) => {
    dispatch(loadingBudgets());
    API.get("budgets", "/", init)
      .then(budgets => {
        //console.log("response budgets", budgets);
        dispatch(getBudgets(budgets, filterParam));
      })
      .catch(error => {
        console.error("ERROR fetching Budgets", error);
        dispatch(getBudgets({}, {}));
      });
  };
}

export function startGetBudgetsById(budgetId) {
  return (dispatch) => {
    dispatch(loadingBudget());
    API.get("budgets", "/" + budgetId + "/", {})
      .then(budget => {
        //console.log("response budget", budget);
        dispatch(getBudget(budget));
      })
      .catch(error => {
        console.error("ERROR fetching budget", error);
        dispatch(getBudget({}));
      });
  };
}

export function startUpdateBudget(budgetId, current_date, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingBudget());
    API.put("budgets", "/" + budgetId + "/t2/actual/", {
      body:{
        closure_month: current_date
      }
    })
      .then(response => {
        //console.log("response update", response);
        dispatch(startGetBudgetsById(budgetId));
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR updating actual", error);
        dispatch(startGetBudgetsById(budgetId));
        onFailure(error);
      });
  };
}

export function startSaveBudget(budgetId, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingBudget());
    API.put("budgets", "/" + budgetId + "/t2/save/", {})
      .then(response => {
        console.log("response save budget", response);
        dispatch(startGetBudgetsById(budgetId));
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR save budget", error);
        dispatch(startGetBudgetsById(budgetId));
        onFailure(error);
      });
  };
}

export function startConfirmBudgetT0(budgetId, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingBudget());
    API.put("budgets", "/" + budgetId + "/t0/confirm/", {})
      .then(response => {
        //console.log("response confirm t0", response);
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR confirm t0", error);
        dispatch(startGetBudgetsById(budgetId));
        onFailure(error);
      });
  };
}

export function startConfirmBudgetT1(budgetId, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingBudget());
    API.put("budgets", "/" + budgetId + "/t1/confirm/", {})
      .then(response => {
        //console.log("response confirm t1", response);
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR confirm t1", error);
        dispatch(startGetBudgetsById(budgetId));
        onFailure(error);
      });
  };
}

export function startCutoffBudgetT2(budgetId, params, onSuccess, onFailure) {
  //console.log("ACTION Budget - startCutoffBudgetT2 ", params);
  return (dispatch) => {
    dispatch(loadingBudgetCutoff());
    API.put("budgets", "/" + budgetId + "/t2/cutOff/", {
      body: params
    })
      .then(response => {
        //console.log("response cutoff t2", response);
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR cutoff t2", error);
        onFailure(error);
      });
  };
}

export function startUpdateBudgetSheet(budgetId, onSuccess, onFailure) {
  //console.log("ACTION Budget - startUpdateBudgetSheet ", budgetId);
  return (dispatch) => {
    dispatch(loadingBudgetCutoff());
    API.put("budgets", "/" + budgetId + "/update/", {})
      .then(response => {
        //console.log("response update sheet", response);
        dispatch(startGetBudgetsById(budgetId));
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR update sheet", error);
        dispatch(startGetBudgetsById(budgetId));
        onFailure(error);
      });
  };
}

export function startChangeBudgetEndDate(budgetId, params, onSuccess, onFailure) {
  //console.log("ACTION Budget - startChangeBudgetEndDate ", budgetId);
  return (dispatch) => {
    dispatch(loadingBudget());
    API.post("budgets", "/" + budgetId + "/cr/", {
      body:params
    })
      .then(response => {
        //console.log("response change request", response);       
        onSuccess(response);
      })
      .catch(error => {
        console.error("ERROR change request", error);

        onFailure(error);
      });
  };
}


