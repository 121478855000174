import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Button, Container, Dimmer, Header, Loader, Segment } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import TypesTable from "../../../components/core/administration/TypesTable";
import CustomerCreationForm from "../../../components/core/customer-creation/CustomerCreationForm";
import CustomerCreationFormModal from "../../../components/core/customer-creation/CustomerCreationFormModal";
import {
  startGetCustomerManagersForCustomerCreation
} from "../../../store/actions/core/administration/customer_manager";
import {
  startApproveCustomer,
  startCreateCustomer,
  startDeleteCustomer,
  startEditCustomer, startGetAllCustomers, startGetCustomerIndustry
} from "../../../store/actions/core/customer-creation/customer";
import { startGetPayment_terms } from "../../../store/actions/core/customer-creation/payment_term";
import { startGetCompanies } from "../../../store/actions/shared/company";
import { startGetCountries } from "../../../store/actions/shared/country";
import constants from "../../../utils/constants";

const customerCols = [
  { id: "code", name: "SAP Code" },
  { id: "name", name: "Customer Name" },
  { id: "country", name: "Country" },
  { id: "headquarter", name: "Headquarter" },
  { id: "industry", name: "Industry" },
  { id: "active", name: "Approved", type: "boolean" },
  { id: "status", name: "Status", type: "status" }
];

class CustomerCreationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
      preview: false,
      listview: true,
      editable: false,
      showFormModal: false,
      approving: false,
      customerCode: "",
      statusFilter: "all",
      filteredCustomers: []
    };
  }

  componentWillMount() {
    this.props.getAllCustomers();
    this.props.getCompanies();
    this.props.getCustomerManagers();
    this.props.getPayment_terms();
    this.props.getCountries();
    this.props.getCustomerIndustry();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers !== this.props.customers) {
      this.filterCustomers();
    }
  }

  filterCustomers = () => {
    const { statusFilter } = this.state;
    const { customers } = this.props;

    if (statusFilter === 'all') {
      this.setState({ filteredCustomers: customers });
    } else if (statusFilter === 'active') {
      this.setState({ filteredCustomers: customers.filter(c => c.status !== false) });
    } else if (statusFilter === 'inactive') {
      this.setState({ filteredCustomers: customers.filter(c => c.status === false) });
    }
  };

  handleStatusFilterChange = (e, { value }) => {
    this.setState({ statusFilter: value }, () => {
      this.filterCustomers();
    });
  };

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showFormModal: false,
      values: {},
      editing: false,
      approving: false,
      preview: false,
      listview: true,
      customerCode: ""
    }));
  };

  handleChange = (e, data) => {
    let values = Object.assign({}, this.state.values);
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      let name_id = '';
      let name_name = '';
      let name_surname = '';
      let name_cn = '';
      if (data.name === "company") {
        name_id = data.name + "_id";
        name_name = data.name + "_name";
        let data_name = data.options.find(o => o.value === data.value).value;
        values[name_name] = data_name;
      } else if (data.name === "industry") {
        name_id = data.name + "_id";
        name_name = data.name + "_name";
        let data_name = data.options.find(o => o.value === data.value).value;
        let data_code = data.options.find(o => o.value === data.value).key;
        values[name_name] = data_name + " (" + data_code + ")";
      } else if (data.name === "manager") {
        name_name = data.name + "_name";
        name_surname = data.name + "_surname";
        name_cn = data.name + "_cn";
        name_id = data.name + "_id";
        let data_name = data.options.find(o => o.value === data.value).name;
        let data_surname = data.options.find(o => o.value === data.value).surname;
        let data_cn = data.options.find(o => o.value === data.value).cn;
        values[name_name] = data_name;
        values[name_surname] = data_surname;
        values[name_cn] = data_cn;
      } else if (data.name === 'payment_terms') {
        name_id = data.name + "_id";
        let data_description = data.name + "_description";
        values[data_description] = data.value;
      } else
        name_id = data.name + "_id";

      values[name_id] = data_id;
      values[data.name] = data.value;
    } else {
      values[data.name] = data.value;
    }

    this.setState({
      values: values
    });
  };

  handleChangeCode = (e) => {
    this.setState({
      customerCode: e.target.value
    });
  };

  onCancel = () => {
    this.setState({
      values: {},
      listview: true,
      showFormModal: false,
      customerCode: ""
    });
  };

  handleNext = () => {
    this.setState({
      preview: true
    });
  }

  handleBack = () => {
    this.setState({
      preview: false
    });
  }

  onCancelApproving = () => {
    this.setState({
      approving: false,
      customerCode: ""
    });
  }

  handleRowClick = selectedRow => {
    // Se lo status non è definito, impostiamo il default a true (attivo)
    if (typeof selectedRow.status === 'undefined') {
      selectedRow.status = true;
    }

    this.setState(prevState => ({
      ...prevState,
      editable: true,
      values: selectedRow,
      showFormModal: true
    }));
  }

  handleEdit = () => {
    this.setState(prevState => ({
      ...prevState,
      editing: true
    })
    );
  };

  handleApprove = () => {
    this.setState(prevState => ({
      ...prevState,
      approving: true
    })
    );
  }

  handleToggleStatus = () => {
    const newStatus = this.state.values.status != false ? false : true;
    const values = Object.assign({}, this.state.values);
    values.status = newStatus;

    if (values.hasOwnProperty("active")) delete values.active;
    if (values.hasOwnProperty("id")) delete values.id;
    if (values.hasOwnProperty("code")) delete values.code;
    if (values.hasOwnProperty("country_id")) delete values.country_id;
    if (values.hasOwnProperty("manager")) delete values.manager;
    if (values.hasOwnProperty("payment_terms")) delete values.payment_terms;

    const onSuccess = res => {
      this.props.getAllCustomers();
      this.setState({ statusFilter: 'all' }, () => {
        this.filterCustomers();

        toast(
          {
            title: "Customer Status",
            description: newStatus ? "Customer activated successfully." : "Customer deactivated successfully.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        this.closeModal();
      });
    };

    const onFailure = res => {
      toast(
        {
          title: "Customer Status",
          description: "Error updating customer status. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    };

    this.props.editCustomer(this.state.values.id, values, onSuccess, onFailure);
  }

  approveSubmit = () => {
    const onFailure = res => {
      toast(
        {
          title: "Customer Creation",
          description: "Error approving the customer. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }

    const onSuccess = res => {
      const values = Object.assign({}, this.state.values);
      values.status = true;

      if (values.hasOwnProperty("active")) delete values.active;
      if (values.hasOwnProperty("code")) delete values.code;
      if (values.hasOwnProperty("country_id")) delete values.country_id;
      if (values.hasOwnProperty("manager")) delete values.manager;
      if (values.hasOwnProperty("payment_terms")) delete values.payment_terms;

      this.props.editCustomer(this.state.values.id, values,
        () => {
          toast(
            {
              title: "Customer Creation",
              description: "Customer approved and activated successfully",
              type: "success",
              icon: "check",
              time: constants.TOAST_SUCCESS_TIMEOUT,
              animation: 'fly left'
            }
          );
          this.closeModal();
        },
        () => {
          toast(
            {
              title: "Customer Creation",
              description: "Customer approved but status update failed",
              type: "warning",
              icon: "warning",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
          this.closeModal();
        }
      );
    };

    this.props.approveCustomer(this.state.values.id, this.state.customerCode, true, onSuccess, onFailure);
  }

  handleDelete = () => {
    const onFailure = res => {
      toast(
        {
          title: "Error",
          description: " The customer hasn't been deleted. Try Again.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    const onSuccess = res => {
      toast(
        {
          title: "Success",
          description: "Customer deleted",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    this.props.deleteCustomer(this.state.values.id, onSuccess, onFailure);
  };

  handleSubmit = () => {
    const onFailure = res => {
      if (JSON.stringify(res).includes("409")) {
        toast(
          {
            title: "Customer Creation",
            description: "The customer name *" + this.state.values.name + "* already exists. Type a new one.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
      } else if (JSON.stringify(res).includes("406")) {
        toast(
          {
            title: "Customer Creation",
            description: res.response.data.error,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
      } else {
        toast(
          {
            title: "Customer Creation",
            description: this.state.values.hasOwnProperty("id") ? "Error editing the customer. Try Again" : " Error creating the customer. Try Again",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      }
    }

    const onSuccess = res => {
      toast(
        {
          title: "Customer Creation",
          description: this.state.values.hasOwnProperty("id") ? "Customer edited successfully." : "Customer created successfully and waiting for approval.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    let values = Object.assign({}, this.state.values);
    if (values.hasOwnProperty("country_id")) delete values.country_id;
    if (values.hasOwnProperty("manager")) delete values.manager;
    if (values.hasOwnProperty("payment_terms")) delete values.payment_terms;

    if (values.hasOwnProperty("active")) delete values.active;
    if (values.hasOwnProperty("id")) delete values.id;
    if (values.hasOwnProperty("code")) delete values.code;

    // Per i nuovi clienti, impostiamo status=false per default
    if (!this.state.values.id && typeof values.status === 'undefined') {
      values.status = false;
    }

    if (this.state.values.id) {
      this.props.editCustomer(this.state.values.id, values, onSuccess, onFailure);
    } else {
      this.props.createCustomer(values, onSuccess, onFailure);
    }
  };

  render() {
    const loading = this.props.loading;
    const { filteredCustomers } = this.state;
    const dataNotLoaded = !this.props.customers || this.props.customers.length === 0;

    return (
      <div>
        <Dimmer active={loading && !dataNotLoaded} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>

        <Container>
          <Header>Customer Creation Request</Header>
          <Can I='customer_creation_request:Read' a='all'>
            {this.state.showFormModal &&
              <CustomerCreationFormModal
                editing={this.state.editing}
                values={this.state.values}
                handleChange={this.handleChange}
                handleChangeCode={this.handleChangeCode}
                onClose={this.closeModal}
                onSubmit={this.handleSubmit}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                loadings={this.props.loadings}
                options={this.props.options}
                onApprove={this.handleApprove}
                approving={this.state.approving}
                customerCode={this.state.customerCode}
                approveSubmit={this.approveSubmit}
                onCancelApproving={this.onCancelApproving}
                onToggleStatus={this.handleToggleStatus}
              />
            }
            {this.state.listview ? (
              <Segment clearing>
                <React.Fragment>
                  <Can I="customer_creation_request:Create" a="all">
                    <Button
                      floated="right"
                      onClick={() => this.setState({ listview: false })}
                    >
                      New Customer
                    </Button>
                  </Can>

                  <TypesTable
                    typeName="customer"
                    loading={loading || dataNotLoaded}
                    types={filteredCustomers.length > 0 ? filteredCustomers : this.props.customers}
                    columns={customerCols}
                    onSelectRow={this.handleRowClick}
                  />
                </React.Fragment>
              </Segment>
            ) : (
              <Segment clearing>
                <CustomerCreationForm
                  values={this.state.values}
                  handleChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                  loadings={this.props.loadings}
                  options={this.props.options}
                  summary={this.state.preview}
                  onCancel={this.onCancel}
                  onNext={this.handleNext}
                  onBack={this.handleBack}
                />
              </Segment>
            )}
          </Can>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.customerReducer.loading,
  customers: state.customerReducer.customers,
  options: {
    country: _.toArray(
      _.mapValues(state.countryReducer.countries, o => {
        return {
          key: o.alpha2Code,
          value: o.name,
          text: o.name + " (" + o.code + ")"
        };
      })
    ),
    company: _.toArray(
      _.mapValues(state.companyReducer.companies, o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    ),
    manager: _.toArray(
      _.mapValues(state.customerManagersReducer.customerManagersBis, o => {
        return {
          key: o.unique_id,
          value: o.name + " " + o.surname,
          text: o.name + " " + o.surname + " (" + o.cn_id + ")",
          name: o.name,
          surname: o.surname,
          cn: o.cn_id
        };
      })
    ),
    industry: _.toArray(
      _.mapValues(state.customerReducer.customersIndustry, o => {
        return {
          key: o.code,
          value: o.name,
          text: o.name,
        };
      })
    ),
    payment_terms: _.toArray(
      _.mapValues(state.payment_termReducer.payment_terms, o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    )
  },
  loadings: {
    company_name: state.companyReducer.loading,
    manager: state.customerManagersReducer.loadingCustomers,
    payment_terms: state.payment_termReducer.loading,
    country: state.countryReducer.loading,
    industry: state.customerReducer.loading,
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getAllCustomers: () => dispatch(startGetAllCustomers()),
    getCompanies: () => dispatch(startGetCompanies("READ_CUSTOMERS")),
    getCountries: () => dispatch(startGetCountries("READ_CUSTOMERS")),
    getPayment_terms: () => dispatch(startGetPayment_terms()),
    createCustomer: (params, onSuccess, onFailure) => dispatch(startCreateCustomer(params, onSuccess, onFailure)),
    deleteCustomer: (id, onSuccess, onFailure) => dispatch(startDeleteCustomer(id, onSuccess, onFailure)),
    approveCustomer: (id, code, onSuccess, onFailure) => dispatch(startApproveCustomer(id, code, onSuccess, onFailure)),
    editCustomer: (id, params, onSuccess, onFailure) => dispatch(startEditCustomer(id, params, onSuccess, onFailure)),
    getCustomerManagers: () => dispatch(startGetCustomerManagersForCustomerCreation("READ_CUSTOMERS")),
    getCustomerIndustry: () => dispatch(startGetCustomerIndustry()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCreationContainer);