export const pages = [
    {
        title: "Home",
        link: "/home",
        icon: "home",
        action: "time_report:Read",
        subject: "my-timereport"
    },
    {
        title: "Time Report",
        link: "/time-report",
        icon: "calendar alternate outline",
        description: 'Report your monthly activities and expenses.',
        action: "time_report:Read",
        subject: "my-timereport"
    },
    {
        title: "Business Card & Signature",
        link: "/signature",
        icon: "vcard",
        description: 'Business Card & Signature',
        action: "email_signature:Read",
        subject: "email_signature"
    },
    {
        title: "Administration",
        icon: "cogs",
        description: 'Access all the administration applications',
        action: "admin:Read",
        link: "/administration",
        subject: "admin",
        content: [
            {
                title: "Manage Companies",
                link: "/admin/manage-companies",
                icon: "building",
                description: 'Create edit deactivate companies.',
                action: "company:Read",
                subject: "all"
            },
            {
                title: "Manage Types",
                link: "/admin/manage-types",
                icon: "wrench",
                description: 'Create edit deactivate hour and expense types.',
                action: "manage_types:Read",
                subject: "all"
            },
            // {
            //     title: "Customer Creation Admin",
            //     link: "/admin/customer-creation-request-admin-values",
            //     icon: "user plus",
            //     description: 'Manage industries and customer managers.',
            //     action: "administration_customer:Read",
            //     subject: "all"
            // },
            {
                title: "Manage Consultants",
                link: "/admin/consultants",
                icon: "users",
                description: 'Search and create external consultants.',
                action: "administration_manage_consultant:Read",
                subject: "all"
            },
            {
                title: "Receipts Admin",
                link: "/admin/receipts",
                icon: "file",
                description: 'Search and manage consultant receipts.',
                action: "administration_receipts:Read",
                subject: "all"
            },
            {
                title: "Manage ACI Coefficients",
                link: "/admin/aci",
                icon: "percent",
                description: 'Search and manage ACI coefficients.',
                action: "administration_aci_coefficients:Read",
                subject: "all"
            },
            {
                title: "Projects Admin",
                link: "/admin/projects",
                icon: "folder open",
                description: 'Search and manage all projects.',
                action: "administration_projects:Read",
                subject: "projects-admin"
            },
            {
                title: "Manage Cut-Off",
                link: "/admin/cutoff",
                icon: "calendar alternate",
                description: 'Search and manage all cutoffs.',
                action: "administration_cut_off:Read",
                subject: "month-cut-off"
            },
            {
                title: "Offer Request Admin",
                icon: "pencil",
                link: "/admin/offer-request",
                description: 'Access all the offer request applications',
                action: "administration_offer_request:Read",
                subject: "offer-request-admin",
                content: [
                    {
                        title: "Offer Request Values",
                        link: "/admin/offer-request/offer-request-admin-values",
                        icon: "wrench",
                        description: 'Manage activity types, offering types, invoice types, project types, product types and sales levels.',
                        action: "administration_offer_request:Read",
                        subject: "offer-request-admin"
                    },
                    /* {
                        title: "Offer Request Logs",
                        link: "/admin/offer-request/offer-request-admin-logs",
                        icon: "file excel outline",
                        description: "Download Offer Request Logs",
                        action: "administration_offer_request:Read",
                        subject: "offer-request-admin"
                    } */
                ]
            },
            {
                title: "Project Planning Admin",
                icon: "calendar check",
                link: "/admin/project-planning",
                description: 'Access all the project planning applications',
                action: "admin:Read",
                subject: "project-planning-admin",
                content: [
                    {
                        title: "External Rates",
                        link: "/admin/project-planning/external-rates",
                        icon: "suitcase",
                        description: "Manage external consultants rates and their exceptions",
                        action: "administration_project_planning:Read",
                        subject: "external-people-rates"
                    },
                    {
                        title: "Standard Costs",
                        link: "/admin/project-planning/standard-costs",
                        icon: "money",
                        description: "Manage internal consultants rates",
                        action: "administration_project_planning:Read",
                        subject: "standard_costs"
                    },
                    // Rimozione (vedi anche modifche BE: new_unit_level_from_talentia)
                    // {
                    //     title: "Approvers Management",
                    //     link: "/admin/project-planning/approvers-management",
                    //     icon: "table",
                    //     description: "Manage PFEs approvers",
                    //     action: "administration_project_planning:Read",
                    //     subject: "approvers_management"
                    // }
                ]
            },
            // Non visibile nel menu
            // {
            //     title: "Hierarchy Management",
            //     link: "/admin/hierarchy-management",
            //     icon: "calendar alternate",
            //     description: 'Manage Techedge hierarchy.',
            //     action: "admin:Read",
            //     subject: "hierarchy_management"
            // },
            /* {
                title: "HR Reports",
                link: "/admin/hr-reports",
                icon: "clipboard",
                description: 'You can download the hour or expenses reports.',
                action: "administration_hr_reports:Read",
                subject: "all"
            }, */
            {
                title: "Admin Order Value",
                link: "/admin/order-value",
                icon: "folder open",
                description: 'Admin Order Value.',
                action: "administration_order_value:Read",
                subject: "all"
            },
            {
                title: "Roles Management",
                link: "/admin/roles-management",
                icon: "sitemap",
                description: "Roles Management",
                action: "roles_management:Read",
                subject: "all"
            }
        ]
    },
    {
        title: "Customer Creation",
        link: "/customer",
        icon: "user plus",
        description: 'You can request the creation of a customer.',
        action: "customer_creation_request:Read",
        subject: "all"
    },
    {
        title: "Projects",
        link: "/project",
        icon: "folder open outline",
        description: 'Here you can review all the projects.',
        action: "projects:Read",
        subject: "projects"
    },
    {
        title: "Reports",
        link: "/reports",
        icon: "clipboard",
        description: 'You can download the hour or expenses reports.',
        action: "reports:Read",
        subject: "my-projects-hours-report"
    },
    {
        title: "Offer Request",
        link: "/offer-request",
        icon: "handshake",
        description: 'You can request the creation of a project.',
        action: "offer_request:Read",
        subject: "offer-request-admin"
    },
    {
        title: "Project Planning",
        icon: "calendar check",
        description: 'You can see the project trends.',
        action: "project_planning:Read",
        link: "/project-planning",
        subject: "project_planning",
        content: [
            // Next
            {
                title: "PFE Next",
                link: "/project-planning/PFE_next",
                icon: "chart line",
                description: "Project resource planning",
                action: "project_planning:Read",
                subject: "pfes_next"
            },
            {
                title: "Budget Next",
                link: "/project-planning/budget_next",
                icon: "euro sign",
                description: "Project resource planning",
                action: "project_planning:Read",
                subject: "budgets_next"
            },
            // Next
        ]
    },
    {
        title: "Invoices",
        icon: "money bill alternate outline",
        description: 'You can manage the project invoices.',
        action: "invoices:Read",
        subject: "invoice",
        link: "/invoices"
    },
    {
        title: "Approval",
        icon: "thumbs up outline",
        description: 'You can approve or reject hour or expenses.',
        action: "approval:Read",
        subject: "all",
        link: "/approval"
    }
    ,
    /*{
        title: "Wip",
        link: "/wip",
        icon: "euro sign",
        description: "Add a new wip to one project",
        action: "wip:Read",
        subject: "all"
    }
    ,*/
    {
        title: "CV Generator",
        link: "/cvGenerator",
        icon: "id badge outline",
        description: "You can manage the CV",
        action: "cv_generator:Read",
        subject: "my-report"
    },
    {
        title: "Daily Progress",
        link: "/daily-progress",
        icon: "clock outline",
        description: "You can manage  the daily progress",
        action: "daily-progress:Read",
        subject: "daily-progress"
    },
    

];